import React, { useMemo } from "react";
import ListItem from "./ListItem";
import * as Styled from "./styled";
import { useSelector } from "react-redux";
import { selectTableData } from "../../tables/tablesSlice";
import { branchShouldBeVisible, isParent } from "../../tables/helpers";
import { selectProjectTreeData } from "./projectTreeSlice";
import { COLUMN_IDS } from "../../tables/constants";
import { getActiveContentId } from "../../panels/helpers";
import { getColumnIndexById } from "../../tables/Columns";

export default function List() {
	let tableId = getActiveContentId();
	const { rows, filteredRowIndex } = useSelector(state => selectTableData(state, tableId));
	const { expandedRowIds } = useSelector(selectProjectTreeData);

	const visibleRows = useMemo(() => {
		let res = [];
		let startRowIndex = 0;
		let lastRowIndex = rows.length - 1;

		for (let rowIndex = startRowIndex; rowIndex <= lastRowIndex; rowIndex++) {
			let row = rows[rowIndex];
			res.push({ rowIndex, row });
			if (!expandedRowIds[row[getColumnIndexById(COLUMN_IDS.ID)].v]) {
				let childIndex = rowIndex + 1;
				while (childIndex < rows.length && rowIndex <= rows[childIndex][getColumnIndexById(COLUMN_IDS.PARENT_INDEX)].v) {
					childIndex++;
				}
				rowIndex = childIndex - 1;
			}
		}

		return res;
	}, [expandedRowIds, rows]);

	return (
		<Styled.List>
			<ListItem value="Progect Name" show={true} tableId={tableId} rowIndex={-1}></ListItem>
			{visibleRows.map(({ row, rowIndex }) => (
				<ListItem
					key={row[getColumnIndexById(COLUMN_IDS.ID)].v}
					rowIndex={rowIndex}
					rowId={row[getColumnIndexById(COLUMN_IDS.ID)].v}
					expanded={expandedRowIds[row[getColumnIndexById(COLUMN_IDS.ID)].v] === 1}
					isParent={isParent(rowIndex)}
					value={row[getColumnIndexById(COLUMN_IDS.ITEM_NAME)].v}
					show={branchShouldBeVisible(rows, expandedRowIds, row)}
					padding={(row[getColumnIndexById(COLUMN_IDS.POSITION)].v.split(".").length - 1) * 12}
					selected={rowIndex === filteredRowIndex}
					tableId={tableId}
				/>
			))}

		</Styled.List>
	);
}
