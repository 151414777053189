import { COLUMN_IDS } from "../tables/constants";
import { getColumnIndexById } from "../tables/Columns";

export function generateEmptyTemplateRow(index) {

	return { description: "", percentage: 0, fixedValue: 0, from: [index], filter: [] };
}

export function findRowsTemplateIndex(rows, rowIndex, bindings) {
	let res = 0;
	let rowId = rowIndex === -1 ? -1 : rows[rowIndex][getColumnIndexById(COLUMN_IDS.ID)].v;
	// if row already has template assigned
	if(bindings[rowId] != null) {
		res = bindings[rowId];
	} else {
		if(rowId === -1) {
			return res;
		}
		// find nearest ancestor with assigned template
		let parentIndex = rows[rowIndex][1];
		while(parentIndex > -1) {
			let parentId = rows[parentIndex][getColumnIndexById(COLUMN_IDS.ID)].v;
			if(bindings[parentId] != null) {
				res = bindings[parentId];
				break;
			}
			parentIndex = rows[parentIndex][1];
		}
	}

	return res;
}
