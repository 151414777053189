import React, { useMemo, useState } from "react";
import * as Styled from "./styled";
import { FixedSizeList as List } from "react-window";
import { useSelector } from "react-redux";
import { selectTableData } from "../tablesSlice";
import Row from "./Row";
import Cell from "./Cell";
import { getActiveContentId } from "../../panels/helpers";
import { COLUMN_IDS } from "../constants";
import useTemplates from "../../../hooks/useTemplates";
import { ReactComponent as Export } from "../../../icons/export.svg";
import { getExcelFromData } from "../../../app/api";
import { getColumnIndexById } from "../Columns";

export default function ExportTable() {
	const tableId = getActiveContentId();
	const tableData = useSelector((state) => selectTableData(state, tableId));
	const { rows, columns } = tableData;
	const [checked, setChecked] = useState([]);
	const { calculateTemplateTotals } = useTemplates({ tableId, rowIndexes: checked });

	function onCheck(id) {
		const nextChecked = [...checked];
		if(nextChecked.includes(id)) {
			nextChecked.splice(nextChecked.indexOf(id), 1);
		} else {
			nextChecked.push(id);
		}
		setChecked(nextChecked);
	}

	const row = ({ index, style }) => (
		<Row index={index} key={`${index}-${allRows[index].id}`} style={style} onCheck={() => onCheck(allRows[index].id)} checked={checked.includes(allRows[index].id)}>
			{
				allRows[index].values.map((cell, cellIndex) => (
					<Cell
						width={columns[cellIndex].width}
						key={`${index}-${cellIndex}`}>
						{
							typeof allRows[index].values[cellIndex] === "number" ?
								Math.round(allRows[index].values[cellIndex] * 100) / 100 :
								allRows[index].values[cellIndex]
						}
					</Cell>
				))
			}
		</Row>
	);

	const tableRows = useMemo(() => {
		return rows.map((row, rowIndex) => {
			const cells = { id: rowIndex, parentIndex: row[getColumnIndexById(COLUMN_IDS.PARENT_INDEX)].v, values: [] };
			columns.forEach((column, columnIndex) => {
				if(column.show) {
					cells.values.push(row[columnIndex].v);
				}
			});

			return cells;
		});
	}, [columns, rows]);

	const tableWidth = useMemo(() => {
		let width = 0;
		columns.forEach((column) => {
			width+=column.width;
		});

		return width;
	}, [columns]);

	const allRows = useMemo(() => {
		const nextRows = [...tableRows];
		for(let i = nextRows.length-1; i >= 0; i--) {
			if(checked.includes(nextRows[i].id)) {
				const templateRows = [];
				calculateTemplateTotals.forEach((templatesByRow, index) => {
					if(nextRows[i].id === Number(templatesByRow.rowIndex)) {
						templatesByRow.templates.slice().reverse().forEach((template) => {
							let templateName = [];
							templateName.push(`position - ${nextRows[i].values[0]}`);
							let header = templateName.concat(Object.keys(template.rows[0]));
							let freeCellCount = nextRows[i].values.length + 1 - header.length;
							header.pop();
							header.unshift(...Array(freeCellCount).fill(""));
							templateRows.push({ values: header });
							template.rows.forEach((row, index) => {
								let res = Object.values(row);
								res.pop();
								res.unshift(...Array(freeCellCount + 1).fill(""));
								let cells = { id: `${nextRows[i].id}_${index}`, parentIndex: `${nextRows[i].id}_${index}`, values: res };
								templateRows.push(cells);
							});
						});
					}
				});

				let siblingIndex = nextRows.length;
				for(let index = i+1; index < nextRows.length; index++) {
					if(nextRows[index].parentIndex <= nextRows[i].parentIndex) {
						siblingIndex = index;

						break;
					}
				}

				nextRows.splice(siblingIndex, 0, ...templateRows);
			}
		}

		return nextRows;
	}, [calculateTemplateTotals, checked, tableRows]);

	function exportClick() {
		const exportedRows = allRows.map((row) => {
			return row.values;
		});

		getExcelFromData(exportedRows);
	}

	return (
		<Styled.Container>
			<div className="button"><Export onClick={exportClick}/></div>
			<Styled.Header width={tableWidth + 100}>
				<Cell>
					<input type="checkbox"/>
				</Cell>
				<Cell isHeader={true} width={60}>N</Cell>
				{
					columns.map((column, columnIndex) => (
						column.show && <Cell
							key={`${columnIndex}-${columnIndex}`}
							width={column.width}
							isHeader={true}
						>
							{column.title}
						</Cell>
					))
				}
			</Styled.Header>
			<List className="list" height={800} itemSize={30} width={tableWidth + 100} itemCount={allRows.length}>
				{row}
			</List>
		</Styled.Container>
	);
}
