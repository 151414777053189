import { CELL_TEXT_ALIGN, COLUMN_IDS, COLUMN_STATUSES } from "./constants";

export const defaultColumns = [
	{ id: COLUMN_IDS.ID, title: "id", type: "number", defaultValue: { v: 1, e: 0 }, show: false, editable: false, width: 0, align: CELL_TEXT_ALIGN.START, status: COLUMN_STATUSES.INITIAL },
	{ id: COLUMN_IDS.PARENT_INDEX, title: "parent index", defaultValue: { v: -1, e: 0 }, type: "number", show: false, editable: false, width: 0, align: CELL_TEXT_ALIGN.START, status: COLUMN_STATUSES.INITIAL },
	{ id: COLUMN_IDS.POSITION, title: "Position", type: "text", defaultValue: { v: "1", e: 0 }, show: true, editable: false, width: 100, align: CELL_TEXT_ALIGN.START, status: COLUMN_STATUSES.INITIAL },
	{ id: COLUMN_IDS.CODE, title: "Code", type: "text", defaultValue: { v: "", e: 0 }, show: true, editable: true, width: 100, align: CELL_TEXT_ALIGN.START, status: COLUMN_STATUSES.INITIAL },
	{ id: COLUMN_IDS.ITEM_NAME, title: "Item Name", type: "text", defaultValue: { v: "", e: 0 }, show: true, editable: true, expandable: true, width: 320, align: CELL_TEXT_ALIGN.START, status: COLUMN_STATUSES.INITIAL },
	{ id: COLUMN_IDS.CATEGORY, title: "Category", type: "text", defaultValue: { v: "", e: 0 }, show: true, editable: true, width: 100, align: CELL_TEXT_ALIGN.CENTER, status: COLUMN_STATUSES.INITIAL },
	{ id: COLUMN_IDS.UNIT, title: "Unit", type: "text", defaultValue: { v: "", e: 0 }, show: true, editable: true, width: 100, align: CELL_TEXT_ALIGN.CENTER, status: COLUMN_STATUSES.INITIAL },
	{ id: COLUMN_IDS.QUANTITY, title: "Quantity", type: "number", defaultValue: { v: null, e: 0 }, show: true, editable: true, width: 100, align: CELL_TEXT_ALIGN.END, status: COLUMN_STATUSES.INITIAL },
	{ id: COLUMN_IDS.QUANTITY_ABSOLUT, title: "Quantity absolut", type: "number", defaultValue: { v: 1, e: 0 }, show: true, editable: true, width: 100, align: CELL_TEXT_ALIGN.END, status: COLUMN_STATUSES.INITIAL },
	{ id: COLUMN_IDS.CATEGORY_MATERIAL_UNIT, title: "Material Unit Cost", type: "number", defaultValue: { v: null, e: 0 }, show: true, editable: true, width: 100, align: CELL_TEXT_ALIGN.END, status: COLUMN_STATUSES.DYNAMIC },
	{ id: COLUMN_IDS.CATEGORY_MATERIAL_TOTAL, title: "Material Total Cost", type: "number", defaultValue: { v: null, e: 0 }, show: true, editable: true, width: 100, align: CELL_TEXT_ALIGN.END, status: COLUMN_STATUSES.DYNAMIC },
	{ id: COLUMN_IDS.CATEGORY_LABOR_UNIT, title: "Labor Unit Cost", type: "number", defaultValue: { v: null, e: 0 }, show: true, editable: true, width: 100, align: CELL_TEXT_ALIGN.END, status: COLUMN_STATUSES.DYNAMIC },
	{ id: COLUMN_IDS.CATEGORY_LABOR_TOTAL, title: "Labor Total Cost", type: "number", defaultValue: { v: null, e: 0 }, show: true, editable: true, width: 100, align: CELL_TEXT_ALIGN.END, status: COLUMN_STATUSES.DYNAMIC },
	{ id: COLUMN_IDS.CATEGORY_EQUIPMENT_UNIT, title: "Equipment Unit Cost", type: "number", defaultValue: { v: null, e: 0 }, show: true, editable: true, width: 100, align: CELL_TEXT_ALIGN.END, status: COLUMN_STATUSES.DYNAMIC },
	{ id: COLUMN_IDS.CATEGORY_EQUIPMENT_TOTAL, title: "Equipment Total Cost", type: "number", defaultValue: { v: null, e: 0 }, show: true, editable: true, width: 100, align: CELL_TEXT_ALIGN.END, status: COLUMN_STATUSES.DYNAMIC },
	{ id: COLUMN_IDS.UNIT_COST, title: "Unit Cost", type: "number", defaultValue: { v: null, e: 0 }, show: true, editable: true, width: 100, align: CELL_TEXT_ALIGN.END, status: COLUMN_STATUSES.INITIAL },
	{ id: COLUMN_IDS.TOTAL_COST, title: "Total Cost", type: "number", defaultValue: { v: null, e: 0 }, show: true, editable: false, width: 100, align: CELL_TEXT_ALIGN.END, status: COLUMN_STATUSES.INITIAL },
];

export function getColumnIndexById(id) {
	return defaultColumns.findIndex(column => column.id === id);
}

