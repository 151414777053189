import { useRef, useState, useMemo } from "react";
import * as Styled from "./styled";
import ToolTip from "../Tooltip/index";
import MenuButton from "./MenuButton/index";
import {
	copyHandler,
	pasteHandler,
	redoHandler,
	undoHandler,
	exportHandler,
	outdentHandler,
	indentHandler,
	extendedTable,
	upRowHandler,
	addChildRowHandler,
	addSiblingRowHandler,
	downRowHandler,
	cutHandler,
} from "../../app/actions";
import { getDataFromExcel } from "../../app/api";
import {
	selectTableData,
	setTable,
	updateRowHeight,
} from "../tables/tablesSlice";
import { useDispatch, useSelector } from "react-redux";
import { changeLoaderVisibility } from "../loader/loaderSlice";
import { defaultColumns, getColumnIndexById } from "../tables/Columns";
import { ReactComponent as AddItem } from "../../icons/add-item.svg";
import { ReactComponent as AddSubItem } from "../../icons/add-subitem.svg";
import { ReactComponent as Copy } from "../../icons/copy.svg";
import { ReactComponent as Paste } from "../../icons/paste.svg";
import { ReactComponent as Cut } from "../../icons/cut.svg";
import { ReactComponent as ItemUp } from "../../icons/arrow-up.svg";
import { ReactComponent as ItemDown } from "../../icons/arrow-down.svg";
import { ReactComponent as Outdent } from "../../icons/outdent.svg";
import { ReactComponent as Indent } from "../../icons/indent.svg";
import { ReactComponent as Undo } from "../../icons/undo.svg";
import { ReactComponent as Redo } from "../../icons/redo.svg";
import { ReactComponent as Columns } from "../../icons/Columns.svg";
import { ReactComponent as Import } from "../../icons/import.svg";
import { ReactComponent as Export } from "../../icons/export.svg";
import { ReactComponent as RowHeightLogo } from "../../icons/row-height-logo.svg";
import { ReactComponent as LevelIcon } from "../../icons/level-icon.svg";

import { getActiveContentId } from "../panels/helpers";
import ClickAndChange from "../clickAndChange";
import { COLUMN_IDS } from "../tables/constants";
import ColumnsPopup from "../columnsPopup";
import Popup from "../../common/ui/Popup";
import ExportTable from "../tables/ExportTable";
import Levels from "./levels";

export default function TableMenu({ tableId }) {
	const inputRef = useRef();
	const dispatch = useDispatch();
	const { rowProps, rows } = useSelector((state) => selectTableData(state, tableId));
	const [rowHeightText, setRowHeightText] = useState("Row Height");
	const [exportDataVisibility, setExportDataVisibility] = useState(false);
	const [showLevel, setShowLevel] = useState(false);

	function clickInput() {
		if (inputRef.current) {
			inputRef.current.click();
		}
	}

	function changeHeight(value) {
		let count = 0;
		for(let row of rows) {
			if(rowProps[row[getColumnIndexById(COLUMN_IDS.ID)].v].selected === true) {
				count++;
				dispatch(updateRowHeight({ tableId, rowId: row[getColumnIndexById(COLUMN_IDS.ID)].v, value }));
			}
		}

		if(count === 0) {
			alert("select Row first");
		}
	}

	async function uploadFile(event) {
		dispatch(changeLoaderVisibility({ show: true }));
		const rows = await getDataFromExcel(event.target.files[0]);
		let tableId = getActiveContentId();
		dispatch(setTable({ rows, tableId }));
		dispatch(changeLoaderVisibility({ show: false }));
	}

	function toggleLevel() {
		setShowLevel(!showLevel);
	}

	const countLevels = useMemo(() => {
		let levels = 0;

		rows.forEach((row) => {
			let position = row[getColumnIndexById(COLUMN_IDS.POSITION)].v.split(".");
			if(position.length > levels) {
				levels = position.length;
			}
		});

		return levels;
	}, [rows]);

	return (
		<Styled.Wrapper>
			<Styled.LeftSection>
				<ToolTip text="Add Item">
					<MenuButton
						showLabel={false}
						label="Add Item"
						onClick={addSiblingRowHandler}
					>
						<AddItem />
					</MenuButton>
				</ToolTip>
				<ToolTip text="Add Subitem">
					<MenuButton
						showLabel={false}
						label="Add Subitem"
						onClick={addChildRowHandler}
					>
						<AddSubItem />
					</MenuButton>
				</ToolTip>
				<ToolTip text="Copy">
					<MenuButton
						showLabel={false}
						label="Copy"
						onClick={copyHandler}
					>
						<Copy />
					</MenuButton>
				</ToolTip>
				<ToolTip text="Paste">
					<MenuButton
						showLabel={false}
						label="Paste"
						onClick={pasteHandler}
					>
						<Paste />
					</MenuButton>
				</ToolTip>
				<ToolTip text="Cut">
					<MenuButton
						showLabel={false}
						label="Cut"
						onClick={cutHandler}
					>
						<Cut />
					</MenuButton>
				</ToolTip>
				<ToolTip text="level">
					<MenuButton
						showLabel={false}
						label="level"
						onClick={toggleLevel}
					>
						{
							showLevel && <Levels levelNum={countLevels}/>
						}
						<LevelIcon />
					</MenuButton>
				</ToolTip>
				<ToolTip text="Move Item Up">
					<MenuButton
						showLabel={false}
						label="Move Item Up"
						onClick={upRowHandler}
					>
						<ItemUp />
					</MenuButton>
				</ToolTip>
				<ToolTip text="Move Item Down">
					<MenuButton
						showLabel={false}
						label="Move Item Down"
						onClick={downRowHandler}
					>
						<ItemDown />
					</MenuButton>
				</ToolTip>
				<ToolTip text="Outdent Item">
					<MenuButton
						showLabel={false}
						label="Outdent Item"
						onClick={outdentHandler}
					>
						<Outdent />
					</MenuButton>
				</ToolTip>
				<ToolTip text="Indent Item">
					<MenuButton
						showLabel={false}
						label="Indent Item"
						onClick={indentHandler}
					>
						<Indent />
					</MenuButton>
				</ToolTip>
				<ToolTip text="Undo">
					<MenuButton
						showLabel={false}
						label="Undo"
						onClick={undoHandler}
					>
						<Redo />
					</MenuButton>
				</ToolTip>
				<ToolTip text="Redo">
					<MenuButton
						showLabel={false}
						label="Redo"
						onClick={redoHandler}
					>
						<Undo />
					</MenuButton>
				</ToolTip>
				<ToolTip text="Row Height">
					<ClickAndChange initialValue={20} onChange={(value) => changeHeight(value)}><RowHeightLogo/></ClickAndChange>
				</ToolTip>
			</Styled.LeftSection>
			<Styled.RightSection>
				<MenuButton
					showLabel={true}
				>
					<ColumnsPopup tableId={tableId}> <Columns /> </ColumnsPopup>
				</MenuButton>
				<MenuButton
					showLabel={true}
					label="Import"
					onClick={clickInput}
				>
					<Import />
				</MenuButton>
				<MenuButton
					showLabel={true}
					label="Export"
					onClick={() => setExportDataVisibility(true)}
				>
					<Export />
				</MenuButton>
				<input ref={inputRef} type="file" onChange={uploadFile} />
			</Styled.RightSection>
			{exportDataVisibility && <Popup onClose={() => setExportDataVisibility(false)}><ExportTable/></Popup>}
		</Styled.Wrapper>
	);
}
