import store from "../../app/store";
import { getActiveContentId } from "../panels/helpers";
import { COLUMN_IDS } from "./constants";

export function* generateNestingLevels() {
	let paddings = [];
	let row;
	while (true) {
		let padding = 0;
		let parentIndex = row ? row[getColumnIndexById(COLUMN_IDS.PARENT_INDEX)].v: -1;

		if (parentIndex > -1) {
			padding = paddings[parentIndex] + 1;
		}

		paddings.push(padding);
		row = yield padding;
	}
}

export function branchShouldBeVisible(rows, expandedRowIds, row) {
	let parent = rows[row[getColumnIndexById(COLUMN_IDS.PARENT_INDEX)].v];
	while (parent) {
		if (expandedRowIds[parent[getColumnIndexById(COLUMN_IDS.ID)].v] === 0 || !expandedRowIds[parent[getColumnIndexById(COLUMN_IDS.ID)].v]) {
			return false;
		}
		parent = rows[parent[getColumnIndexById(COLUMN_IDS.PARENT_INDEX)].v];
	}

	return true;
}

export function findNextSiblingsIndex(rows, rowIndex, columns) {
	let siblingsIndex = rowIndex + 1;
	for (let i = rowIndex + 1; i < rows.length; i++) {
		if (rows[i][getColumnIndexById(COLUMN_IDS.PARENT_INDEX, columns)].v <= rows[rowIndex][getColumnIndexById(COLUMN_IDS.PARENT_INDEX, columns)].v) {
			siblingsIndex = i;
			break;
		}
		siblingsIndex = i + 1;
	}

	return siblingsIndex;
}

export function increaseParentIndexes(rows, minParentIndex, start = 0, increaseBy = 1, columns) {
	for (let i = start; i < rows.length; i++) {
		if (rows[i][getColumnIndexById(COLUMN_IDS.PARENT_INDEX, columns)].v > minParentIndex) {
			rows[i][getColumnIndexById(COLUMN_IDS.PARENT_INDEX, columns)].v += increaseBy;
		}
	}
}

export function calculateRowWidth(columns) {
	let fullWidth = 0;
	if (columns) {
		columns.forEach(column => fullWidth += column.width);
	}

	return fullWidth;
}

export function registerIf(condition) {
	let cb;

	return function(handler, ...args) {
		if (!condition) return undefined;
		if (!cb) {
			cb = (...eventArgs) => handler(...args, ...eventArgs);
		}

		return cb;
	};
}

export function roundTo(number, digits = 0) {
	return Math.round(number * 10 ** digits) / 10 ** digits;
}

export function parseValue(value, type) {
	switch (type) {
	case "number":
		return Number(value);
	default:
		return String(value);
	}
}

export function checkIsNumber(value) {
	return RegExp(/^[0-9]+\.?([0-9]+)?$/).test(value);
}

export function parseNumber(value) {
	if (value[value.length - 1] === ".") {
		return Number(value.slice(0, -1));
	}

	return value;
}

export function getLastChildIndex(index, rows) {
	let lastIndex = null;
	for (let i = index + 1; i < rows.length; i++) {
		if (rows[i][getColumnIndexById(COLUMN_IDS.PARENT_INDEX)].v >= index) {
			lastIndex = i;
		} else {
			break;
		}
	}

	return lastIndex;
}

export function getLastVisibleChildIndex(index, rows, rowProps) {
	if (!rowProps[rows[index][getColumnIndexById(COLUMN_IDS.ID)].v].expanded) {
		return null;
	}

	let lastIndex = null;

	for (let i = index + 1; i < rows.length; i++) {
		if (rows[i][getColumnIndexById(COLUMN_IDS.PARENT_INDEX)].v <= rows[index][getColumnIndexById(COLUMN_IDS.PARENT_INDEX)].v) {
			break;
		}
		lastIndex = i;
		if (!rowProps[rows[i][getColumnIndexById(COLUMN_IDS.ID)].v].expanded) {
			let currentIndex = i;
			for (let j = i + 1; j < rows.length; j++) {
				if (rows[j][getColumnIndexById(COLUMN_IDS.PARENT_INDEX)].v <= rows[currentIndex][getColumnIndexById(COLUMN_IDS.PARENT_INDEX)].v) {
					break;
				}
				i++;
			}
		}
	}

	return lastIndex;
}

export function isParent(rowIndex, rows, columns) {
	if(!rows) {
		let tableId = getActiveContentId();
		rows = store.getState().tables[tableId].rows;
	}
	if (rowIndex === rows.length - 1) {
		return false;
	}

	return rowIndex === rows[rowIndex + 1][getColumnIndexById(COLUMN_IDS.PARENT_INDEX, columns)].v;
}

export function childrenCount(rows, rowIndex) {
	let count = 0;
	for (let i = rowIndex + 1; i < rows.length; i++) {
		if (rows[i][getColumnIndexById(COLUMN_IDS.PARENT_INDEX)].v <= rows[rowIndex][getColumnIndexById(COLUMN_IDS.PARENT_INDEX)].v) {
			break;
		}
		count++;
	}

	return count;
}

export function createSingleRowProps(expanded = true, selected = false, height = 20) {
	return {
		expanded,
		selected,
		height,
	};
}

export function generateInitialRowProps(number, expanded = true, selected = false) {
	let res = {};

	for (let i = 1; i <= number; i++) {
		res[i] = createSingleRowProps(expanded, selected);
	}

	return res;
}

export function getChildren(rows, rowIndex) {
	let res = [];
	let childIndex = rowIndex + 1;
	while (rows[childIndex] && rows[childIndex][getColumnIndexById(COLUMN_IDS.PARENT_INDEX)].v >= childIndex) {
		res.push(rows[childIndex]);
		childIndex++;
	}

	return res;
}

export function* generateNestedRows(rows, rowIndex, resourcesOnly = false) {
	let childIndex = rowIndex + 1;
	if (childIndex >= rows.length) {
		return;
	}
	while (rows[childIndex] && rows[childIndex][getColumnIndexById(COLUMN_IDS.PARENT_INDEX)].v >= rowIndex) {
		if (!resourcesOnly || !isParent(childIndex)) {
			yield { index: childIndex, row: rows[childIndex] };
		}
		childIndex++;
	}
}

export function calculateNextTableId() {
	const tableIds = Object.keys(store.getState().tables);

	return Math.max(...tableIds) + 1;
}

export function getAncestorsCount(row, tableId, filteredRowIndex) {
	const rows = store.getState().tables[tableId].rows;
	const columns = store.getState().tables[tableId].columns;
	let firstRowIndex = filteredRowIndex != null && filteredRowIndex !== -1 ?
		rows[filteredRowIndex][getColumnIndexById(COLUMN_IDS.PARENT_INDEX, columns)].v : -1;
	let parentIndex = row[getColumnIndexById(COLUMN_IDS.PARENT_INDEX, columns)].v;
	let res = 0;
	while(parentIndex > firstRowIndex) {
		res++;
		parentIndex = rows[parentIndex][getColumnIndexById(COLUMN_IDS.PARENT_INDEX, columns)].v;
	}

	return res;

}

export function* generateDescendantIndexes(rowIndex, rows, columns) {

	for(let i = rowIndex + 1; i < rows.length; i++) {
		if(rows[i][getColumnIndexById(COLUMN_IDS.PARENT_INDEX, columns)].v < rowIndex) {
			break;
		}
		yield i;
	}
}

export function getSelectedValue(rowIndex, rows, rowProps) {
	let tableId = getActiveContentId();
	const columns = store.getState().tables[tableId].columns;

	if(rowIndex !== -1) {
		let rowId = rows[rowIndex][getColumnIndexById(COLUMN_IDS.ID)].v;
		if(!rowProps[rowId].selected) {
			return 0;
		}
	}

	let iterator = generateDescendantIndexes(rowIndex, rows, columns);
	for(let childIndex of iterator) {
		let childId = rows[childIndex][getColumnIndexById(COLUMN_IDS.ID, columns)].v;

		if(!rowProps[childId].selected) {
			return rowIndex === -1 ? 0 : 2;
		}
	}

	return 1;
}

export function buildCategoryId(value) {
	return value.toLowerCase().replace(/\s/g, "-");
}

export function extractCategoryName(id) {
	return id.split("_")[1].replace(/-/g, " ").toLowerCase();
}

export function getColumnIndexById(id, columns, tableId) {
	if(columns || tableId) {
		return columns.findIndex(column => column.id === id);
	}

	tableId = getActiveContentId();

	return store.getState().tables[tableId].columns.findIndex(column => column.id === id);
}

