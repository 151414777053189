import { history } from "./modules/history";
import store from "./store";
import { addRow, changeColumnVisibility, clearRowSelection, downRows, indentRows, outdentRows, removeRow, replaceRow, setTableState, upRows, updateCell } from "../features/tables/tablesSlice";
import { generateDescendantIndexes, isParent } from "../features/tables/helpers";
import { getExcelFromData } from "./api";
import { downloadFile } from "./modules/file";
import { getActiveContentId } from "../features/panels/helpers";
import { ADD_ROW_OPTION, COLUMN_IDS } from "../features/tables/constants";
import { getColumnIndexById } from "../features/tables/Columns";

export function undoHandler() {
	const activeContentId = getActiveContentId();
	let prevState = history.prev(activeContentId);
	if (prevState) {
		store.dispatch(setTableState({ nextState: prevState, tableId: activeContentId }));
	}
}

export function redoHandler() {
	const activeContentId = getActiveContentId();
	let nextState = history.next(activeContentId);
	if (nextState) {
		store.dispatch(setTableState({ nextState, tableId: activeContentId }));
	}
}
export function copyHandler() {
	const state = store.getState();
	const activeContentId = getActiveContentId();
	const tableData = state.tables[activeContentId];

	if (tableData.selectedCell.rowIndex !== null) {
		navigator.clipboard.writeText(tableData.rows[tableData.selectedCell.rowIndex][tableData.selectedCell.cellIndex].v);

		return;
	}
	let text = "";

	tableData.rows.forEach((row, index) => {
		if (tableData.rowProps[row[getColumnIndexById(COLUMN_IDS.ID)].v].selected) {
			tableData.columns.forEach((column, colIndex) => {
				if (column.show) {
					text += (row[colIndex].v ?? "") + "\t";
				}
			});
			text = text.slice(0, -1);
			text += "\n";
		}
	});
	text = text.slice(0, -1);

	navigator.clipboard.writeText(text);
	store.dispatch(clearRowSelection({}));
}

export function cutHandler() {
	const state = store.getState();
	const activeContentId = getActiveContentId();
	const tableData = state.tables[activeContentId];

	let removeRowIndexes = [];
	tableData.rows.forEach((row, index) => {
		if (tableData.rowProps[row[getColumnIndexById(COLUMN_IDS.ID)].v].selected) {
			if(!removeRowIndexes.includes(row[getColumnIndexById(COLUMN_IDS.PARENT_INDEX)].v)) {
				removeRowIndexes.push(index);
			}
		}
	});
	copyHandler();

	for(let i = removeRowIndexes.length - 1; i >= 0; i--) {
		store.dispatch( removeRow({ index: removeRowIndexes[i] }));
	}
}

export function pasteHandler() {
	let state = store.getState();
	const activeContentId = getActiveContentId();
	const tableData = state.tables[activeContentId];
	const visibleColumns = tableData.columns.filter(column => column.show);
	const selectedRowIndexes = [];

	// get selected rows
	for (let i = 0; i < tableData.rows.length; i++) {
		if (tableData.rowProps[tableData.rows[i][getColumnIndexById(COLUMN_IDS.ID)].v].selected) {
			if (i - selectedRowIndexes[selectedRowIndexes.length - 1] > 1) {
				alert("rows must be selected in order!");

				return;
			}
			selectedRowIndexes.push(i);
		}
	}

	if (selectedRowIndexes.length > 0) {
		let decendants = generateDescendantIndexes(selectedRowIndexes[selectedRowIndexes.length - 1], tableData.rows);
		let allselectedRowIndexes = [...selectedRowIndexes, ...decendants];
		// build rows array from clipboard text
		navigator.clipboard.readText().then(text => {
			let rows = text.split("\n");
			rows = rows.map(text => text.split("\t"));
			for (let row of rows) {
				if (row.length !== visibleColumns.length) {
					alert("can not paste!");

					return;
				}
				visibleColumns.forEach((column, index) => {
					let value = row[index];
					let edited = 0;
					if(column.type === "number") {
						if(value === "") {
							value = null;
						} else {
							value = Number(row[index]);
						}
					}
					if(index === getColumnIndexById(COLUMN_IDS.QUANTITY_ABSOLUT) && row[index - 1].v === null) {
						edited = 1;
					}
					row[index] = { v: value, e: edited };
				});
			}
			store.dispatch(replaceRow({ removeIndexes: allselectedRowIndexes, rows }));
		});

		return;
	}

	if (tableData.selectedCell.cellIndex) {
		let columnIndex = tableData.columns.findIndex((col, i) => i === tableData.selectedCell.cellIndex);

		navigator.clipboard.readText().then(text => {
			store.dispatch(updateCell({ rowIndex: tableData.selectedCell.rowIndex, columnIndex, value: text }));
		});

		return;
	}
}

export async function exportHandler() {
	const state = store.getState();
	const activeContentId = getActiveContentId();
	let data = state.tables[activeContentId];
	const columns = data.columns.map(col => col.title);

	const blob = await getExcelFromData({ rows: data.rows, columns });
	downloadFile(blob, "exported_" + Date.now() + ".xlsx");
}

export function outdentHandler() {
	const state = store.getState();
	const tableId = getActiveContentId();
	let table = state.tables[tableId];

	let lastSelectedRowId = null;

	for(const props in table.rowProps) {
		if(table.rowProps[props].selected) {
			lastSelectedRowId = props;
		}
	}

	table.rows.forEach((row, rowIndex) => {
		if (table.rowProps[row[getColumnIndexById(COLUMN_IDS.ID)].v].selected || table.selectedCell.rowIndex === rowIndex) {
			if(row[getColumnIndexById(COLUMN_IDS.PARENT_INDEX)].v !== -1) {
				const parentRowId = table.rows[row[getColumnIndexById(COLUMN_IDS.PARENT_INDEX)].v][getColumnIndexById(COLUMN_IDS.ID)].v;
				if(table.rowProps[parentRowId].selected) return;
			}

			if(row[getColumnIndexById(COLUMN_IDS.ID)].v === Number(lastSelectedRowId)) {
				store.dispatch(outdentRows({ rowIndex, isLast: true }));
			} else {
				store.dispatch(outdentRows({ rowIndex, isLast: false }));
			}
		}
	});
}

export function indentHandler() {
	const state = store.getState();
	const tableId = getActiveContentId();
	let table = state.tables[tableId];

	let lastSelectedRowId = null;

	for(const props in table.rowProps) {
		if(table.rowProps[props].selected) {
			lastSelectedRowId = props;
		}
	}

	table.rows.forEach((row, rowIndex) => {
		if (table.rowProps[row[getColumnIndexById(COLUMN_IDS.ID)].v].selected || table.selectedCell.rowIndex === rowIndex) {
			if(row[getColumnIndexById(COLUMN_IDS.PARENT_INDEX)].v !== -1) {
				const parentRowId = table.rows[row[getColumnIndexById(COLUMN_IDS.PARENT_INDEX)].v][getColumnIndexById(COLUMN_IDS.ID)].v;
				if(table.rowProps[parentRowId].selected) return;
			}

			if(row[getColumnIndexById(COLUMN_IDS.ID)].v === Number(lastSelectedRowId)) {
				store.dispatch(indentRows({ rowIndex, isLast: true }));
			} else {
				store.dispatch(indentRows({ rowIndex, isLast: false }));
			}
		}
	});
}

export function addChildRowHandler() {
	const state = store.getState();
	const activeContentId = getActiveContentId();
	const { rows, rowProps, selectedCell } = state.tables[activeContentId];
	let count = 0;
	for(let i = 0; i < rows.length; i++) {
		if (rowProps[rows[i][getColumnIndexById(COLUMN_IDS.ID)].v].selected || selectedCell.rowIndex === i) {
			store.dispatch(addRow({ index: i + count, option: ADD_ROW_OPTION.CHILD }));
			if(!isParent(i, rows)) {
				count++;
			}
		}
	}
}

export function addSiblingRowHandler() {
	const state = store.getState();
	const activeContentId = getActiveContentId();
	const { rows, rowProps, selectedCell } = state.tables[activeContentId];
	let count = 0;
	for(let i = 0; i < rows.length; i++) {
		if (rowProps[rows[i][getColumnIndexById(COLUMN_IDS.ID)].v].selected || selectedCell.rowIndex === i) {
			store.dispatch(addRow({ index: i + count, option: ADD_ROW_OPTION.SIBLING_BELOW }));
			if(!isParent(i, rows)) {
				count++;
			}
		}
	}
}

export function extendedTable() {
	store.dispatch(changeColumnVisibility());
}

export function upRowHandler() {
	const state = store.getState();
	const activeContentId = getActiveContentId();
	const { rows, rowProps, selectedCell } = state.tables[activeContentId];

	for(let i = 1; i < rows.length; i++) {
		if(i === rows[i][getColumnIndexById(COLUMN_IDS.PARENT_INDEX)].v) {
			break;
		}

		if (rowProps[rows[i][getColumnIndexById(COLUMN_IDS.ID)].v].selected || selectedCell.rowIndex === i) {
			if(!rowProps[rows[i - 1][getColumnIndexById(COLUMN_IDS.ID)].v].selected) {
				store.dispatch(upRows({ rowIndex: i }));
			}
		}
	}

}

export function downRowHandler() {
	const state = store.getState();
	const activeContentId = getActiveContentId();
	const { rows, rowProps, selectedCell } = state.tables[activeContentId];

	for(let i = 0; i < rows.length; i++) {
		if (rowProps[rows[i][getColumnIndexById(COLUMN_IDS.ID)].v].selected || selectedCell.rowIndex === i) {
			store.dispatch(downRows({ rowIndex: i }));
			if(isParent(i, rows)) {
				break;
			}
		}
	}
}

