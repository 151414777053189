import { createSlice } from "@reduxjs/toolkit";
import { createSingleRowProps, extractCategoryName, findNextSiblingsIndex, generateDescendantIndexes, generateInitialRowProps, increaseParentIndexes } from "./helpers";
import { ADD_ROW_OPTION, EXTRA_UI_COLUMNS, COLUMN_IDS } from "./constants";
import { updateTableWithFormulas } from "./formulas";
import { getActiveContentId } from "../panels/helpers";
import { getColumnIndexById } from "./Columns";

function createEmptyRow(id, columns) {
	let rows = columns.map(column => ({ ...column.defaultValue }));
	let idIndex = columns.findIndex(column => column.id === COLUMN_IDS.ID);
	rows[idIndex].v = id;

	return rows;
}

const tableSlice = createSlice({
	name: "tables",
	initialState: {},
	reducers: {
		createTable(state, action) {
			let { tableId, data } = action.payload;
			state[tableId] = data;
			updateTableWithFormulas(state[tableId]);
		},
		setTable(state, action) {
			let { tableId, rows } = action.payload;
			if (!state[tableId]) throw Error(`table with id ${tableId} does not exist!`);
			state[tableId].rowProps = generateInitialRowProps(rows.length);
			state[tableId].nextId = rows.length + 1;
			state[tableId].rows = rows;
		},
		setColumns(state, action) {
			let { tableId, columns } = action.payload;
			state[tableId].columns = columns;
		},

		setFilteredRowIndex(state, action) {
			let { tableId, rowIndex } = action.payload;
			state[tableId].filteredRowIndex = rowIndex;
		},
		addRow(state, action) {
			let { index, option, _panels } = action.payload;
			let tableId = getActiveContentId(_panels);

			let { rows, rowProps, columns } = state[tableId];
			let rowIndex;
			const newRow = createEmptyRow(state[tableId].nextId, columns);
			rowProps[state[tableId].nextId] = createSingleRowProps();
			state[tableId].nextId++;

			switch (option) {
			case ADD_ROW_OPTION.CHILD:
				newRow[getColumnIndexById(COLUMN_IDS.PARENT_INDEX)].v = index;
				rowIndex = findNextSiblingsIndex(rows, index);
				increaseParentIndexes(rows, index, rowIndex + 1);
				break;
			case ADD_ROW_OPTION.SIBLING_BELOW:
				newRow[getColumnIndexById(COLUMN_IDS.PARENT_INDEX)].v = rows[index][getColumnIndexById(COLUMN_IDS.PARENT_INDEX)].v;
				rowIndex = findNextSiblingsIndex(rows, index);
				increaseParentIndexes(rows, index, rowIndex + 1);
				break;
			case ADD_ROW_OPTION.SIBLING_ABOVE:
				newRow[getColumnIndexById(COLUMN_IDS.PARENT_INDEX)].v = rows[index][getColumnIndexById(COLUMN_IDS.PARENT_INDEX)].v;
				rowIndex = index;
				increaseParentIndexes(rows, rows[index][getColumnIndexById(COLUMN_IDS.PARENT_INDEX)].v, rowIndex + 1);
				break;
			default:
				return;
			}
			rows.splice(rowIndex, 0, newRow);
			updateTableWithFormulas(state[tableId]);
		},
		replaceRow(state, action) {
			let { removeIndexes, rows = [], _panels } = action.payload;
			rows = structuredClone(rows);
			console.log(rows);
			let tableId = getActiveContentId(_panels);

			if (rows.length === 0) {
				// replace rows with single empty row
				const newEmptyRow = createEmptyRow(state[tableId].nextId, state[tableId].columns);
				state[tableId].rowProps[state[tableId].nextId] = createSingleRowProps();
				state[tableId].nextId++;
				state[tableId].rows.splice(removeIndexes[0], 1, newEmptyRow);
			} else {
				let pasteIndex = removeIndexes[0];
				let parentIndex = state[tableId].rows[removeIndexes[0]][getColumnIndexById(COLUMN_IDS.PARENT_INDEX)].v;
				let levelsToIndexes = { [rows[0][0].v.split(".").length]: parentIndex };
				rows.forEach((row, index) => {
					// save mapping between levels and parent indexes
					// assign new parent index to each element by its level
					let level = row[0].v.split(".").length;
					if (levelsToIndexes[level] != null) {
						row.unshift({ v: levelsToIndexes[level], e: 0 });
						levelsToIndexes[level + 1] = pasteIndex + index;
					} else {
						if (level < rows[0][2].v.split(".").length) {
							row.unshift({ v: parentIndex, e: 0 });
						} else {
							row.unshift({ v: pasteIndex + index - 1, e: 0 });
						}
					}
					// add id and update rowprops
					row.unshift({ v: state[tableId].nextId, e: 0 });
					state[tableId].rowProps[state[tableId].nextId] = createSingleRowProps();
					state[tableId].nextId++;
				});
				// replace selected rows with new rows and update parent indexes of rest
				state[tableId].rows.splice(removeIndexes[0], removeIndexes.length, ...rows);
				let deletedCount = rows.length - removeIndexes.length;
				for (let i = removeIndexes[0] + rows.length; i < state[tableId].rows.length; i++) {
					if (state[tableId].rows[i][getColumnIndexById(COLUMN_IDS.PARENT_INDEX)].v > parentIndex) {
						state[tableId].rows[i][getColumnIndexById(COLUMN_IDS.PARENT_INDEX)].v += deletedCount;
					}
				}
			}
			updateTableWithFormulas(state[tableId]);
		},
		removeRow(state, action) {
			let { index, _panels } = action.payload;
			let tableId = getActiveContentId(_panels);
			let rows = state[tableId].rows;
			let columns = state[tableId].columns;
			let removeCount = 1;

			for (let i = index + 1; i < rows.length; i++) {
				if (rows[i][getColumnIndexById(COLUMN_IDS.PARENT_INDEX)].v >= index && rows[i][getColumnIndexById(COLUMN_IDS.PARENT_INDEX).v] < index + removeCount) {
					removeCount++;
				} else if (rows[i][getColumnIndexById(COLUMN_IDS.PARENT_INDEX)].v > index) {
					rows[i][getColumnIndexById(COLUMN_IDS.PARENT_INDEX)].v -= removeCount;
				}
			}
			rows.splice(index, removeCount);

			if (rows.length === 0) {
				rows.push(createEmptyRow(1, columns));
			}

			updateTableWithFormulas(state[tableId]);
		},
		resizeColumn(state, action) {
			const { tableId, index, size } = action.payload;
			state[tableId].columns[index].width = size;
		},
		toggleRowExpandedStatus(state, action) {
			const { id, _panels } = action.payload;
			let tableId = getActiveContentId(_panels);

			const { rowProps } = state[tableId];

			rowProps[id].expanded = !rowProps[id].expanded;
		},
		updateCell(state, action) {
			const { rowIndex, columnIndex, value, _panels } = action.payload;
			let tableId = getActiveContentId(_panels);
			const { rows, columns } = state[tableId];

			let cellData = null;
			let unitCostIndex = getColumnIndexById(COLUMN_IDS.UNIT_COST);
			if (columns[columnIndex].id.includes("_unit")) {
				let categoryIndex = getColumnIndexById(COLUMN_IDS.CATEGORY);
				cellData = rows[rowIndex][unitCostIndex];
				rows[rowIndex][categoryIndex].v = extractCategoryName(columns[columnIndex].id);

			} else {
				cellData = rows[rowIndex][columnIndex];
			}

			if (value === cellData.v) {
				return;
			}
			cellData.v = value;
			cellData.e = 1;
			if (columns[columnIndex].type !== "number" && columnIndex !== getColumnIndexById(COLUMN_IDS.CATEGORY)) return;
			updateTableWithFormulas(state[tableId], { rowIndex, columnIndex });
		},
		updateRowHeight(state, action) {
			const { tableId, rowId, value } = action.payload;
			const { rowProps } = state[tableId];

			rowProps[rowId].height = Number(value);
		},
		setTableState(state, action) {
			const rows = action.payload.nextState.rows;
			let tableId = action.payload.tableId;
			state[tableId].rows = [...rows];
		},
		outdentRows(state, action) {
			const { rowIndex, isLast, _panels } = action.payload;
			let tableId = getActiveContentId(_panels);

			let rows = state[tableId].rows;
			if (rows[rowIndex][getColumnIndexById(COLUMN_IDS.PARENT_INDEX)].v === -1) {
				return;
			}
			let nextIndex = rowIndex + 1;

			while (nextIndex < rows.length && rows[nextIndex][getColumnIndexById(COLUMN_IDS.PARENT_INDEX)].v >= rows[rowIndex][getColumnIndexById(COLUMN_IDS.PARENT_INDEX)].v) {
				if (rows[nextIndex][getColumnIndexById(COLUMN_IDS.PARENT_INDEX)].v === rows[rowIndex][getColumnIndexById(COLUMN_IDS.PARENT_INDEX)].v) {
					rows[nextIndex][getColumnIndexById(COLUMN_IDS.PARENT_INDEX)].v = rowIndex;
				}
				nextIndex++;
			}
			rows[rowIndex][getColumnIndexById(COLUMN_IDS.PARENT_INDEX)].v = rows[rows[rowIndex][getColumnIndexById(COLUMN_IDS.PARENT_INDEX)].v][getColumnIndexById(COLUMN_IDS.PARENT_INDEX)].v;
			isLast && updateTableWithFormulas(state[tableId]);
		},
		indentRows(state, action) {
			const { rowIndex, isLast, _panels } = action.payload;
			let tableId = getActiveContentId(_panels);

			let rows = state[tableId].rows;
			let parentIndex = rows[rowIndex][getColumnIndexById(COLUMN_IDS.PARENT_INDEX)].v;

			for (let i = rowIndex - 1; i > parentIndex; i--) {
				if (parentIndex === rows[i][getColumnIndexById(COLUMN_IDS.PARENT_INDEX)].v) {
					state[tableId].rows[rowIndex][getColumnIndexById(COLUMN_IDS.PARENT_INDEX)].v = i;
					break;
				}
			}
			isLast && updateTableWithFormulas(state[tableId]);
		},
		upRows(state, action) {
			let { rowIndex, _panels } = action.payload;
			if (rowIndex === 0) return;

			let tableId = getActiveContentId(_panels);
			let rows = state[tableId].rows;
			let parentIndex = rows[rowIndex][getColumnIndexById(COLUMN_IDS.PARENT_INDEX)].v;

			let moveIndex = rowIndex;

			for (let i = rowIndex - 1; i > rows[rowIndex][getColumnIndexById(COLUMN_IDS.PARENT_INDEX)].v; i--) {
				if (rows[i][getColumnIndexById(COLUMN_IDS.PARENT_INDEX)].v === parentIndex) {
					moveIndex = i;
					break;
				}
			}

			if (moveIndex === rowIndex) return;

			// collect copied row and its descendant
			let rowsToMove = [rows[rowIndex]];
			let iterator = generateDescendantIndexes(rowIndex, rows);
			for (let index of iterator) {
				rowsToMove.push(rows[index]);
				rows[index][getColumnIndexById(COLUMN_IDS.PARENT_INDEX)].v -= (rowIndex - moveIndex);
			}

			// update parents of previous neighbours children
			iterator = generateDescendantIndexes(moveIndex, rows);
			for (let index of iterator) {
				rows[index][getColumnIndexById(COLUMN_IDS.PARENT_INDEX)].v += rowsToMove.length;
			}
			rows.splice(rowIndex, rowsToMove.length);
			rows.splice(moveIndex, 0, ...rowsToMove);

			updateTableWithFormulas(state[tableId]);

		},
		downRows(state, action) {
			let { rowIndex, _panels } = action.payload;
			if (rowIndex === 0) return;

			let tableId = getActiveContentId(_panels);
			let rows = state[tableId].rows;
			let parentIndex = rows[rowIndex][getColumnIndexById(COLUMN_IDS.PARENT_INDEX)].v;

			let moveIndex = rowIndex;

			for (let i = rowIndex + 1; i < rows.length; i++) {
				if (rows[i][getColumnIndexById(COLUMN_IDS.PARENT_INDEX)].v === parentIndex) {
					moveIndex = i;
					break;
				}
			}
			if (moveIndex === rowIndex) return;

			let rowsToMoveDown = [rows[rowIndex]];
			let rowsToUp = [rows[moveIndex]];

			let iterator = generateDescendantIndexes(rowIndex, rows);
			let iterator1 = generateDescendantIndexes(moveIndex, rows);
			let it = generateDescendantIndexes(rowIndex, rows);

			for (let index of iterator) {
				rowsToMoveDown.push(rows[index]);
			}

			for (let index of iterator1) {
				rowsToUp.push(rows[index]);
				rows[index][getColumnIndexById(COLUMN_IDS.PARENT_INDEX)].v -= rowsToMoveDown.length;
			}

			for (let index of it) {
				rows[index][getColumnIndexById(COLUMN_IDS.PARENT_INDEX)].v += rowsToUp.length;
			}
			rows.splice(moveIndex, rowsToUp.length);
			rows.splice(rowIndex, 0, ...rowsToUp);

			updateTableWithFormulas(state[tableId]);
		},
		changeColumnVisibility(state, action) {
			const { index, tableId } = action.payload;
			const columns = state[tableId].columns;
			columns[index].show = !columns[index].show;
		},
		visibleAllColumns(state, action) {
			const { tableId } = action.payload;
			const columns = state[tableId].columns;

			for (let index in columns) {
				columns[index].show = true;
			}
		},
		resetColumnVisibility(state, action) {
			const { tableId } = action.payload;
			const columns = state[tableId].columns;

			columns.forEach(column => column.show = false);
		},
		selectRow(state, action) {
			const { rowIndex, _panels, withChildren, combine } = action.payload;
			let tableId = getActiveContentId(_panels);
			// select current row
			if (rowIndex !== -1) {
				let rowId = state[tableId].rows[rowIndex][getColumnIndexById(COLUMN_IDS.ID)].v;
				state[tableId].rowProps[rowId].selected = true;
			}
			// select descendants
			let iterator = generateDescendantIndexes(rowIndex, state[tableId].rows);
			for (let index of iterator) {
				console.log(index);
				let childId = state[tableId].rows[index][getColumnIndexById(COLUMN_IDS.ID)].v;
				state[tableId].rowProps[childId].selected = withChildren;
			}
			// select with shiftkey
			if (combine) {
				let select = false;
				for (let i = 0; i < rowIndex; i++) {
					let childId = state[tableId].rows[i][getColumnIndexById(COLUMN_IDS.ID)].v;
					if (state[tableId].rowProps[childId].selected) {
						select = true;
					} else if (select) {
						state[tableId].rowProps[childId].selected = true;
					}
				}

				select = false;
				for (let i = state[tableId].rows.length - 1; i > rowIndex; i--) {
					let childId = state[tableId].rows[i][getColumnIndexById(COLUMN_IDS.ID)].v;
					if (state[tableId].rowProps[childId].selected) {
						select = true;
					} else if (select) {
						state[tableId].rowProps[childId].selected = true;
					}
				}
			}
		},
		unselectRow(state, action) {
			const { rowIndex, _panels } = action.payload;
			let tableId = getActiveContentId(_panels);
			if (rowIndex !== -1) {
				let rowId = state[tableId].rows[rowIndex][getColumnIndexById(COLUMN_IDS.ID)].v;
				state[tableId].rowProps[rowId].selected = false;
			}
			// unselect children
			let iterator = generateDescendantIndexes(rowIndex, state[tableId].rows);
			for (let index of iterator) {
				let childId = state[tableId].rows[index][getColumnIndexById(COLUMN_IDS.ID)].v;
				state[tableId].rowProps[childId].selected = false;
			}
		},
		clearRowSelection(state, action) {
			const { _panels } = action.payload;
			let tableId = getActiveContentId(_panels);
			Object.values(state[tableId].rowProps)
				.forEach(prop => {
					if (prop.selected) {
						prop.selected = false;
					}
				});
		},
		selectCell(state, action) {
			const { rowIndex, cellIndex, tableId } = action.payload;
			state[tableId].selectedCell.rowIndex = rowIndex;
			state[tableId].selectedCell.cellIndex = cellIndex;
		},
		unselectAllCells(state, action) {
			const { _panels } = action.payload;
			let activeTabs = _panels.activeTabs;
			activeTabs.forEach((tabIndex, panelIndex) => {
				if (tabIndex != null) {
					let tableId = _panels.tabs[panelIndex][tabIndex].contentId;
					state[tableId].selectedCell = { cellIndex: null, rowIndex: null };
				}
			});
		},
		changeColumnWidth(state, action) {
			const { newWidth, cellIndex, tableId } = action.payload;
			state[tableId].columns[cellIndex].width = newWidth;
		},

		condenseAllRows(state, action) {
			const { _panels } = action.payload;
			let tableId = getActiveContentId(_panels);

			const { rows, rowProps } = state[tableId];
			rows.forEach(row => {
				rowProps[row[getColumnIndexById(COLUMN_IDS.ID)].v].expanded = false;
			});
		},
		expandAllRows(state, action) {
			const { _panels } = action.payload;
			let tableId = getActiveContentId(_panels);

			const { rows, rowProps } = state[tableId];
			rows.forEach(row => {
				rowProps[row[getColumnIndexById(COLUMN_IDS.ID)].v].expanded = true;
			});
		},
		expendByLevels(state, action) {
			const { level, _panels } = action.payload;
			let tableId = getActiveContentId(_panels);

			const { rows, rowProps } = state[tableId];
			rows.forEach(row => {
				let dots = row[getColumnIndexById(COLUMN_IDS.POSITION)].v.split(".");
				rowProps[row[getColumnIndexById(COLUMN_IDS.ID)].v].expanded = dots.length <= level;
			});
		},
	},
});

export function selectTableData(state, tableId) {
	return state.tables[tableId];
}

export function selectTableRows(state, tableId) {
	return state.tables[tableId].rows;
}

export function selectFilteredRowIndex(state, tableId) {
	return state.tables[tableId].filteredRowIndex;
}

export function selectCellsCountInRow(state, tableId) {
	return state.tables[tableId].columns.filter(column => column.show).length + EXTRA_UI_COLUMNS;
}

export function selectCustomCategories(state, tableId) {
	return state.tables[tableId].customCategories;
}

export const {
	updateCell,
	setTable,
	replaceRow,
	addRow,
	removeRow,
	resizeColumn,
	toggleRowExpandedStatus,
	setTableState,
	updateColumns,
	setColumns,
	setFilteredRowIndex,
	outdentRows,
	indentRows,
	changeColumnVisibility,
	resetColumnVisibility,
	createTable,
	selectRow,
	unselectRow,
	selectCell,
	unselectAllCells,
	clearRowSelection,
	upRows,
	downRows,
	updateRowHeight,
	visibleAllColumns,
	changeColumnWidth,
	condenseAllRows,
	expandAllRows,
	expendByLevels,
} = tableSlice.actions;

export default tableSlice.reducer;
