import React, { useState } from "react";
import * as Styled from "./styled";
import { selectCustomCategories, setNewCategories } from "../../tables/tablesSlice";
import { useDispatch, useSelector } from "react-redux";

export default function SelectCategory({ tableId }) {
	const categories = useSelector(state => selectCustomCategories(state, tableId));
	const dispatch = useDispatch();
	const [showInput, setShowInput] = useState(false);
	const [value, setvalue] = useState("");

	console.log(categories);

	function addCategory() {
		dispatch(setNewCategories( { newCategory: value, tableId }));
		setShowInput(false);
	}

	return (
		<Styled.Wrapper>
			<ul>
				{
					categories.map(category => <li>{category}</li>)
				}
			</ul>
			<div>
				{
					!showInput ?
						<button onClick={() => setShowInput(true)}>Add category</button> :
						<>
							<input type="text" onChange={event => setvalue(event.currentTarget.value)}/>
							<button onClick={addCategory}>Add</button>
						</>
				}
			</div>
		</Styled.Wrapper>
	);
}
