import React from "react";
import * as Styled from "./styled";
import DetailsCell from "./DetailsCell";
import { useDispatch, useSelector } from "react-redux";
import { selectTableData, updateCell } from "../../tables/tablesSlice";
import { COLUMN_IDS } from "../../tables/constants";
import { useMemo } from "react";
import { getActiveContentId } from "../../panels/helpers";
import { getColumnIndexById } from "../../tables/Columns";

export default function RowProps({ tableId }) {
	// let tableId = getActiveContentId();
	const { columns, rowProps, rows } = useSelector((state) => selectTableData(state, tableId));

	const cell = useMemo(() => {
		const selectedRow = rows.find((row) => rowProps[row[getColumnIndexById(COLUMN_IDS.ID)].v].selected);
		const rowIndex = rows.indexOf(selectedRow);

		const res = [];

		if(selectedRow) {
			for(let i = 0; i < columns.length; i++) {
				if(columns[i].show) {
					res.push(<DetailsCell key={i} title={selectedRow[i].v} rowIndex={rowIndex} columnIndex={i}/>);
				}
			}
		}

		return res;
	}, [columns, rowProps, rows]);

	return (
		<Styled.Wrapper>
			<Styled.TitlesWrapper>
				{
					columns.map((column, index) => column.show && <div key={index}>{column.title}</div>)
				}
			</Styled.TitlesWrapper>
			<Styled.DetailsWrapper>
				{cell}
			</Styled.DetailsWrapper>
		</Styled.Wrapper>
	);
}
