import React, { useCallback, memo, useState, useMemo } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import * as Styled from "./styled";
import { useDispatch } from "react-redux";
import { resizeColumn, setRowExpandedStatus, updateCell } from "../tablesSlice";
import { config } from "../../../app/config.js";
import { highlight } from "../../selection/selectionSlice";
import { SELECTION_CONTEXT } from "../../selection/constants";
import { roundTo } from "../helpers";
import { faCaretDown, faCaretRight, faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as LockIcon } from "../../../icons/lock.svg";
import { ReactComponent as VerticalLine } from "../../../icons/vertical-line.svg";
import TextEditor from "./TextEditor";
import { getColumnIndexById } from "../helpers";

function Cell({
	cellProps,
	expanded,
	isHeader,
	isFirstColumn,
	nestingLevel,
	column,
	columnIndex,
	rowIndex,
	edited,
	align,
	tableId,
	highlightCell,
	showArrow,
	toggleExpanded,
	onSelect,
	selected,
	uniqueCategories,
	row,
}) {
	const dispatch = useDispatch();
	const currentClientWidthRef = useRef(0);
	const isMouseDownRef = useRef(false);
	const lineRef = useRef();
	const wrapperRef = useRef();
	const initialCellWidthRef = useRef(column.width);
	const [editing, setEditing] = useState(false);

	function startEditing() {
		if (!column.editable || isFirstColumn) {
			return;
		}
		setEditing(true);
	}

	function updateValue(value) {
		dispatch(updateCell({ rowIndex, columnIndex, value }));
		setEditing(false);
	}

	const onMouseDownHandler = useCallback((e) => {
		currentClientWidthRef.current = e.clientX;
		isMouseDownRef.current = true;
	}, []);

	const onMouseMovehandler = useCallback((e) => {
		if (!isMouseDownRef.current) {
			return;
		}
		const change = e.clientX - currentClientWidthRef.current;

		if (
			initialCellWidthRef.current + change > config.table.minColWidth &&
			window.innerWidth > e.clientX &&
			initialCellWidthRef.current + change < config.table.maxColWidth
		) {
			dispatch(resizeColumn({ index: columnIndex, size: initialCellWidthRef.current + change, tableId }));
		}

	}, [columnIndex, dispatch, tableId]);

	const onMouseUpHandler = useCallback(e => {
		initialCellWidthRef.current = column.width;
		isMouseDownRef.current = false;
	}, [column.width]);

	useEffect(() => {
		if (isHeader) {
			lineRef.current.addEventListener("mousedown", onMouseDownHandler);
			window.addEventListener("mouseup", onMouseUpHandler);
			window.addEventListener("mousemove", onMouseMovehandler);
		}
	}, [onMouseDownHandler, onMouseMovehandler, onMouseUpHandler, isHeader]);

	function renderValue(value) {
		return typeof value === "number" ? roundTo(value, 2) : value === "" || value == null ? "-" : value;
	}

	const levelLine = useMemo(() => {
		const lines = [];
		for(let i=0; i<=nestingLevel; i++) {
			if(i !== 0) {
				lines.push(<div></div>);
			}
			if(i === nestingLevel && column.expandable && showArrow) {
				lines.push(<FontAwesomeIcon isLine={false} icon={expanded ? faChevronDown : faChevronRight} size="xs" onClick={toggleExpanded} onDoubleClick={toggleExpanded} />);
			}
		}

		return lines;
	}, [column.expandable, expanded, nestingLevel, showArrow, toggleExpanded]);

	return (
		<Styled.Wrapper
			width={column.width}
			align={align}
			isFirstColumn={isFirstColumn}
			isHeader={isHeader}
			ref={wrapperRef}
			onDoubleClick={startEditing}
			edited={edited && column.formula}
			editing={editing}
			onClick={onSelect}
			selected={selected}
		>
			<Styled.Content
				isLocked={false}
				editable={column.editable}
				isHeader={isHeader}
				nestingLevel={nestingLevel}
				showArrow={showArrow}
			>
				{levelLine}

				<span>
					{renderValue(cellProps.v)}
				</span>
			</Styled.Content>

			{editing &&
				<TextEditor
					type={column.type}
					initialValue={cellProps.v}
					onSubmit={updateValue}
				/>
			}
		</Styled.Wrapper>
	);
}

export default memo(Cell);
