import styled from "styled-components";

export const Wrapper = styled.div`
	background-color: #fff;
    padding: 10px;

    ul {
        all: unset;
        list-style-type: none;

        li {
            padding: 5px;
            border-bottom: 1px solid silver;
        }
    }

    div {
        margin-top: 10px;
    }

`;

